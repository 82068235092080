@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;800&display=swap');
*{
    font-family: 'Nunito', sans-serif;
    margin: 0;
    padding: 0;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

body, html{
    background-image: url('../images/bg-white.jpeg');
    background-size: cover;
    font-family: Poppins, Helvetica, "sans-serif";
    font-size: 13px !important;
}

h1, h2, h3, h4, p, ul, ol{
    margin: 0;
    padding: 0;
}

h1, h2{
    font-weight: 800;
}

label{
    font-weight: 600;
}

.header{
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0px;
    align-items: center;
    justify-content: space-between;
}

.header-cont-1{
    display: flex;
    align-items: center;
}

.iconMovMont{
    width: 55px;
    display: flex;
    margin-left: 20px;
    justify-content: space-between;
}

.textWhite{
    color: #a1a5b7;
}

.zkskz {
    width: 100%;
    z-index: 9;
    height: 100vh;
    position: absolute;
}

.iconMovMont svg{
    font-size: 20px;
    cursor: pointer;
}

.iconMovMont svg:hover{
    color: #50cd89;
}

.div-container-404{
    width: 100%;
    display: flex;
    color: #ccc;
    height: 100vh;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.div-container-404 svg{
    border-radius: 100%;
}

.div-container-404 h1{
    margin-top: 20px;
    font-size: 3rem;
}

.text-gray{
    color: #6a6a85;
}

.fs-18{
    font-size: 18px;
}

.fs-16{
    font-size: 16px;
}

.fs-14{
    font-size: 14px;
}

.container-login{
    align-items: center;
    display: flex;
    min-height: 100vh;
}

.title-login{
    font-size: 22px;
}

.subtitle{
    line-height: 1.2;
}

.img-login{
    max-width: 520px;
}

.logo-login{
    margin-bottom: 30px;
    max-width: 450px;
    width: 100%;
}

.div-button-login{
    height: 40px;
    display: flex;
    align-items: center;
}

.text-center{
    margin-bottom: 20px;
}

.container-pass-acc{
    height: 40px;
}

.btn-general{
    background-color: #50cd89 !important;
    border: none !important;
    font-size: 15px;
    color: #fff !important;
}

.btn-general a{
    text-decoration: none;
    color: #3f4254;
}

.btn-general:hover{
    background-color: #50cd89;
    box-shadow: 0 0 0 0.2rem rgb(80, 205, 137, 0.5);
    color: #3f4254;
    font-size: 15px;
}

.btn-eliminar{
    background-color: #cd5050 !important;
}

.btn-eliminar:hover{
    box-shadow: 0 0 0 0.2rem rgba(205, 80, 80, 0.5);
}

.btn-general span{
    display: flex;
    justify-content: center;
    align-items: center;
}

.spin{
    animation-name: spin;
    animation-duration: 2500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.forgot-password, .got-to-signup{
    color: #a1a5b7;
    cursor: pointer;
    text-decoration: none;

    transition: all 0.15s ease-in-out;
}

.forgot-password:hover, .got-to-signup:hover{
    color: #a1a5b7;
    font-size: 15px;
    opacity: 0.85;
}

.modal-content input,
.modal-content select,
.modal-content textarea,
.modal-content input:focus,
.modal-content textarea:focus,
.content-inputs .form-control{
    border: 1px solid #a1a5b7 !important;
    color: #3f4254 !important;
}

.modal-content textarea:focus,
.modal-content select:focus{
    box-shadow: none;
    border: 1px solid #323248;
}

textarea#notasg,
textarea#notasj {
    height: auto;
}

.form-select,
.form-select option{
    border: 1px solid #a1a5b7 !important;
    color: #3f4254 !important;
    background-color: #f3f4f9;
}

.modal-content input:focus,
.form-select:focus{
    box-shadow: none;
    border: 1px solid #323248;
}

.containerCal,
.calendarNav .form-floating option,
.content-inputs .form-floating label{
    color: #3f4254 !important;
}

.image-signup{
    max-width: 200px;
    width: 100%;
}

.container-formsignup{
    background-color: transparent !important;
    border-radius: 0.5rem;
    margin: 0 auto;
    min-height: 90vh;
    padding: 75px 15px;
}

.descriptionsignup{
    margin: 0 auto;
    max-width: 500px;
}

.validate-format-password-su span{
    background-color: #bfbfbf;
    border-radius: 5px;
    height: 4px;
    margin-bottom: 15px;
    width: 30%;
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

/*    Recover Password    */

.containerInput{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 17px;
    width: 100%;
}

#agendaForm .containerInput{
    height: auto;
}

.containerButtomRecove {
    height: 31px;
    display: flex;
    justify-content: center;
}

.containerInput .form-floating {
    width: 44%;
}

.modal-content{
    background-image: url('../images/bg-white.jpeg');
    color: #3f4254 !important;
}

.btn-clos{
    background: transparent url('../images/boton-x.png') center/1em auto no-repeat !important;
}

/*      Calendario      */

.lineDivi{
    width: 100%;
    background-color: #3f4254;
    height: 1px;
}

.iconTheme{
    font-size: 18px;
    color: #3f4254;
    padding: 10px;
}

.iconTheme:hover{
    color: #50cd89;
    cursor: pointer;
}

.containerPrivateIcons{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.iconsLock{
    margin-right: 20px;
    width: 24px;
}

.container-right{
    display: flex;
    align-items: center;
    flex-direction: column;
}

.container-right svg{
    font-size: 16px;
}

.container-notifications{
    width: 450px;
    padding: 10px;
    z-index: 99;
    margin-top: 50px;
    margin-left: -30%;
    position: absolute;
    border-radius: 10px;
    background-color: #fff;
}

.notification{
    display: flex;
    padding: 10px 0px;
    margin-bottom: 10px;
    border-radius: 10px;
    background-color: #f3f4f9;
}

.notification-icon{
    display: flex;
    align-items: center;
    justify-content: center;
}

.notification .notification-data{
    padding-left: 10px;
    border-left: 1px solid #e7e7e7;
}

.notification .notification-data h4,
.notification .notification-data h2{
    font-size: 13px;
    font-weight: bold;
}

.notification .notification-data h4{
    font-size: 10.5px;
}

.notification .notification-data p{
    font-size: 12px;
    text-align: justify;
}

.notification .notification-time{
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cont-menu{
    background-color: #f3f4f9;
}

.container-menu{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    padding: 10px 30px;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.container-menu #icon-img{
    width: 300px;
}

.iconModalEvent{
    width: 20px;
    height: 20px;
    margin-left: 13px;
}

.containerIconEvent{
    display: flex;
}

.container-menu #imagen-user{
    width: 50px;
    height: 50px;
    cursor: pointer;
    margin-bottom: 0;
    border-radius: 10px;
}

.containerUser {
    display: flex;
    margin-left: 5px;
    align-items: center;
}

.containerUser h4{
    color: #3f4254;
    font-size: 15px;
    margin-right: 20px;
    margin-top: 5px;
}

.nav-menu{
    margin-top: 10px;
    margin-right: 25px;
}

.nav-menu ul{
    display: flex;
}

.nav-menu a{
    font-size: 15px;
    padding: 14px 10px;
    text-decoration: none;
    color: #3f4254 !important;
}

.nav-menu a:hover{
    background-color: #50cd89;
    border-radius: 5px;
}

#container-btn-agendar{
    color: #3f4254;
    width: 85%;
    margin-left: 7.5%;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

#agendarBtn{
    width: 10% !important;
    height: 40px;
    background-color: #50cd89;
    border: none;
}

.btn-primary:focus{
    box-shadow: 0 0 0 0.2rem rgb(80, 205, 137, 0.5) !important;
    border: none;
}

.toastui-calendar-layout.toastui-calendar-month{
    background-color: transparent !important;
}

.toastui-calendar-weekday-grid,
.toastui-calendar-daygrid-cell{
    border: 1px solid #323248 !important;
}

.toastui-calendar-template-monthDayName,
span.toastui-calendar-weekday-grid-date.toastui-calendar-template-monthGridHeader{
    color: #ccc;
}

.toastui-calendar-template-monthDayName{
    text-align: center;
}

.toastui-calendar-grid-cell-date .toastui-calendar-weekday-grid-date.toastui-calendar-weekday-grid-date-decorator{
    background-color: #50cd89 !important;
    color: #323248 !important;
}

.toastui-calendar-weekday-event{
    background-color: #6a6a857d !important;
    border: 1px solid #fff;
    color: #3f4254 !important;
    margin-top: 10px;
}

.toastui-calendar-weekday-event-title{
    color: #3f4254 !important;
}

.invitadosContainer .form-floating{
    width: 94% !important;
    margin-left: 3%;
}

input[type="date"]::-webkit-inner-spin-button,
::-webkit-calendar-picker-indicator{
    filter: drop-shadow(16px 16px 20px rgb(255, 255, 255)) invert(100%);
}

.lblEvent{
    margin-left: 5px;
    font-size: 14px;
}

.calendarNav{
    display: flex;
    width: 50%;
    padding: 20px;
    align-items: center;
}

.calendarNav .form-floating{
    width: 20%;
    margin-left: 20px;
}

.toastui-calendar-detail-container{
    color: #000;
}

/*      Semana      */
.container,
.toastui-calendar-layout .toastui-calendar-week-view {
    background-color: transparent;
}

.toastui-calendar-layout.toastui-calendar-day-view,
.toastui-calendar-day-name-container span,
.toastui-calendar-layout.toastui-calendar-week-view{
    background-color: transparent !important;
    color: #3f4254 !important;
}

span.toastui-calendar-template-timegridNowIndicatorLabel{
    color: #50cd89;
}

.toastui-calendar-timegrid-now-indicator-today,
.toastui-calendar-timegrid-now-indicator-left{
    border-top: 1px dashed rgb(80, 205, 137) !important;
}

.toastui-calendar-timegrid-now-indicator-marker{
    background-color: rgb(80, 205, 137) !important;
}

.toastui-calendar-timegrid-time{
    color: #ccc !important;
}

.selected-day{
    background-color: #50cd89;
}

.navbar-dark button {
    margin: 0px 15px;
    background-color: #50cd89 !important;
}

.navbar-dark button:focus,
.navbar-dark button:hover{
    border: 1px solid #50cd89;
    box-shadow: 0 0 0 0.2rem rgb(80, 205, 137, 0.5) !important;
}


/*      Estilos de Tags       */

.listTag{
    width: 91%;
    height: 70px;
    position: absolute;
    margin-left: 3%;
    background-color: #f3f4f9;
    overflow: overlay;
    z-index: 99999999;
    padding: 5px 10px;
    flex-direction: column;
}

input[type = 'checkbox']{
    display: none;
}

input[type='checkbox']:checked{
    background-size: 50% 100% !important;
}

.filter-color input[type='checkbox']{
    border: 1px solid #50cd89;
}

.filter-color input[type='checkbox']:checked{
    background-size: 100% 100% !important;
    color: #50cd89;
}

.listTag label{
    padding: 7px 3px;
}

.listTag label:hover{
    background-color: #fff;
    cursor: pointer;
}

.containerMasterTag{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 7px;
}

.containerTag{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.modal-content .containerTag input{
    border: none !important;
}

.iconCloseTag{
    width: 0px;
    display: flex;
    padding: 0px 9px;
    text-indent: 15px;
    align-items: center;
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: 5px;
    margin-right: 10px;
    background-image: url('../images/cancelar.png');
}

.iconCloseTag:hover{
    cursor: pointer;
}

#custom-switch {
    display: block;
    font-size: 15px;
}

.sw-label label{
    font-size: 15px;
}

.form-check-input:checked,
.form-switch .form-check-input:checked {
    background-color: #50cd89;
    border-color: rgb(80, 205, 137, 0.5) !important;
}

.form-switch .form-check-input,
.form-switch .form-check-input:focus{
    background-color: rgb(80, 205, 137, 0.5) !important;
    border-color: rgb(80, 205, 137, 0.5) !important;
}

.form-switch .form-check-input,
.form-switch .form-check-input:focus{
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e") !important;
}


.form-floating-check{
    width: 44%;
}

.modalRegisterHospite{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 9;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-image: url('../images/bg-white.jpeg');
}

.modalRegister {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-image: url('../images/bg-white.jpeg');
}

.modalRegister h3{
    margin-bottom: 5%;
    margin-left: 20px;
}

.modalRegister .containerInput {
    margin-left: -13px;
    padding: 10px;
}

.containerInput .button{
    width: 80%;
    margin-left: 10%;
}

.containerInput .button button{
    margin: 20px;
}

.containerInput .button .btn-cancel{
    background-color: rgb(184, 8, 8) !important;
}

.containerInput .button .btn-cancel:hover{
    box-shadow: 0 0 0 0.2rem rgba(205, 80, 80, 0.5) !important;
}

.containerInput .iconTheme{
    color: #fff;
}

/*      confirm email       */

.loading{
    width: 25%;
    margin-left: 43%;
    background-image: url('../images/circles-menu-1.json');
}

.text-loading{
    width: 100%;
    text-align: center;
    color: #3f4254;
    font-size: 30px;
}

/*      Logo weport loading       */

.ContarinerLoading{
    width: 100%;
    z-index: 99;
    top: 0;
    left: 0;
    display: flex;
    height: 100vh;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-image: url('../images/bg-white.jpeg');
}

#img-load-cal{
    border-radius: 100%;
}

.help-modal-h2 img{
    border-radius: 10px;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.container-Calendar{
    color: #3f4254;
}

.filter-color{
    display: flex;
}

.filter-color input{
    display: block;
}

.filter-color span{
    color: #3f4254 !important;
}

.conr-lbl-filter {
    display: flex;
    justify-content: space-between;
}

.containerLBLFilter svg{
    margin-left: 10px;
}

.imageSalaFilter{
    margin-top: 20px;
}

.imageSalaFilter img{
    border-radius: 10px;
}

.imageSalaFlt{
    display: none;
}

.imageSalaFltModal{
    width: 100%;
    margin: auto;
}

.lblFilter{
    align-items: center;
}

.containerFilter svg{
    padding: 15px 10px;
}

.form-select:disabled,
.form-control:disabled{
    background-color: transparent !important;
}

.containerMenuUser{
    cursor: pointer;
}

.containerMenuUser ul{
    /* display: none; */
    padding: 5px 20px;
    border-radius: 5px;
    position: absolute;
    flex-direction: column;
    background-color: #f3f4f9;
    border: 1px solid #a1a5b7;
}

.containerMenuUser li{
    padding: 5px 10px;
}

.containerMenuUser li:hover{
    background-color: #fff;
    border-radius: 5px;
}

.containerMenuUser li a{
    text-decoration: none;
    color: #000;
}

.imgImageUser{
    display: flex;
    align-items: center;
}

/* .imgImageUser:hover ~ ul,
.imgImageUser ~ ul:hover{
    display: flex;
} */

.view-not-user{
    padding-top: 1.625rem;
    border: none !important;
    padding-bottom: 0.625rem;
    background-color: transparent !important;
}

.aside-container{
    width: 15%;
    padding: 2%;
}

.aside-container button{
    width: 100%;
}

.ContainerIconName{
    display: flex;
    margin-top: 25px;
    align-items: center;
}

.ContainerIconName p{
    margin-top: 16px;
}

#iconDataUser{
    font-size: 20px;
    color: #3f4254;
    top: 132px;
    position: relative;
    left: 100px;
    animation-name: clickMe;
    animation-duration: 4s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

@keyframes clickMe{
    0% { opacity: 1.0; }
    50% { opacity: 0.0; }
    100% { opacity: 1.0; }
}

#buttUser{
    display: none;
}

.labelForUser{
    display: block;
    position: relative;
    top: -150px;
    height: 150px;
    border-radius: 100%;
    cursor: pointer;
}

.iconFilter:hover{
    color: #3f4254;
    cursor: auto;
}

#containerDataUser{
    width: 100%;
}

#formDataUser{
    width: 60%;
    display: flex;
    margin-left: 20%;
    margin-top: 20px;
    align-items: center;
    flex-direction: column;
}

#formDataUser img{
    width: 150px;
    height: 150px;
    border-radius: 100%;
}

#formDataUser .content-inputs{
    width: 100%;
}

.contImageUser{
    height: 200px;
}

#containerInvitados{
    width: 94%;
    margin-left: 3%;
    padding: 4px 10px;
}

.titleInvitados{
    opacity: .65;
    font-size: 11.5px;
}

.dataInvitadosContainer{
    display: flex;
    flex-wrap: wrap;
}

.dataInvitadosContainer p{
    margin-right: 12px;
}


#Directory{
    width: 80%;
    margin-left: 10%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.containerImageUser{
    display: flex;
    border-radius: 100%;
    align-items: center;
    margin-bottom: 18px;
    justify-content: center;
}

.containerDirectory{
    width: 90%;
    display: flex;
    margin-left: 5%;
    margin-top: 20px;
    padding: 20px 5px;
    border-radius: 20px;
    align-items: center;
    flex-direction: column;
    background-color: #f3f4f9;
}

.containerDirectory .imgUser{
    width: 95px;
    height: 95px;
    padding: 5px;
    border-radius: 100%;
    background-color: #fff;
    border: 1px solid #f3f4f9;
}

.containerDirectory .imgLogo{
    width: 30px;
    padding: 4px;
    margin-top: 55px;
    margin-left: -25px;
    position: relative;
    border-radius: 100%;
    background-color: #fff;
    border: 1px solid #f3f4f9;
}

.containerDataCardUser{
    width: 80%;
}

.containerDirectory svg{
    width: 17px;
}

.containerDataCardUser h2{
    font-size: 16px;
    text-align: center;
}

.containerDataCardUser h3{
    margin-top: 10px;
    font-size: 15px;
    text-align: center;
    margin-bottom: 7px;
}

.containerDataCardUser h4{
    padding: 10px;
    font-size: 15px;
    border-radius: 5px;
    border: 1px dashed #646464;
}

.emailUser{
    text-align: center;
    margin: 13px 0px;
}

.conatainerColumUsr{
    width: 100%;
    display: flex;
    margin-top: 20px;
    align-items: center;
    justify-content: space-around;
}

.separador{
    width: 2px;
    height: 25px;
    border-right: 1px solid #646464;
}

.help-modal{
    width: 100%;
    height: 100vh;
    color: #3f4254;
    top: 0;
    left: 0;
    display: flex;
    position: fixed;
    align-items: center;
    flex-direction: column;
}

.help-modal-icon{
    width: 100%;
    margin-top: 20px;
    margin-right: 15%;
    position: absolute;
}

.help-modal-h2{
    margin-top: 10px;
}

.help-modal-h2 img {
    margin: auto;
}

.help-modal-icon-fa{
    font-size: 65px;
    float: right;
    animation: animationArrow 4s infinite;
}

.cont-Rec-pass{
    width: 40%;
    margin: auto;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cont-Rec-pass form{
    width: 100%;
}

.container-row{
    width: 100%;
}

#container-col-0,
#container-col-1,
#container-col-2,
#container-col-3,
#container-col-4,
#container-col-5{
    display: flex;
    min-height: 20vh;
}

#container-col-0 .border,
#container-col-1 .border,
#container-col-2 .border,
#container-col-3 .border,
#container-col-4 .border,
#container-col-5 .border{
    width: 20%;
    max-width: 20%;
}

.container-event{
    height: 11vh;
    overflow: hidden;
}

.cont-btn-more{
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 5px 0px;
}

.style-bottom-more{
    color: #fff;
    display: flex;
    font-size: 17px;
    width: 35px;
    height: 35px;
    border-radius: 100%;
    padding: 8px 0px;
    align-items: center;
    justify-content: center;
    background-color: rgb(80, 205, 137);
}

.container-dashboard-body{
    width: 75%;
    height: 100vh;
}

.dashboard-body-container{
    width: 80%;
    height: 400px;
    margin-top: 30px;
    margin-left: 10%;
}

.carousel-img{
    width: 100%;
    display: block;
    border-radius: 20px;
}

.container-dashboard-side{
    width: 25%;
    height: 100vh;
}

.dashboard-side-container{
    width: 90%;
    margin-top: 30px;
    margin-left: 5%;
}

.dashboard-side-notifications{
    height: 30rem;
    /* overflow: hidden; */
    padding: 10px 10px;
}

.side-notifications-container{
    height: 22rem;
    overflow-x: scroll;
}

.side-antiquity-user{
    display: flex;
    padding: 10px 20px;
    border-radius: 10px;
    margin-bottom: 10px;
    justify-content: space-around;
    background-color: #f3f4f9;
}

.side-antiquity-container{
    height: 30rem;
    padding: 10px 20px;
    overflow-x: scroll;
}

.side-antiquity-user h2{
    font-size: 16px;
}

.side-antiquity-user h3{
    font-size: 14px;
}

@keyframes animationArrow {
    100% {transform: scale(1.3);}
    50% {transform: scale(1);}
    0% {transform: scale(1.3);}
}

@media(min-width:1200px){
    .container-formsignup{
        background-color: transparent;
        border-radius: 0.5rem;
        margin: 0 auto;
        min-height: 90vh;
        padding: 60px 45px;
    }
}

@media(max-width: 1110px){
    .help-modal-icon{
        position: relative;
    }

    #agendarBtn{
        width: 20% !important;
    }

    .calendarNav .form-floating {
        width: 50%;
    }

    .cont-btn-created{
        display: flex;
        align-items: center;
    }

    .cont-btn-filt{
        height: auto;
    }

    .containerFilter{
        display: flex;
        align-items: center;
        margin-left: 5px;
    }

    .containerFilter.containerLBLFilter.mb-2 {
        margin-bottom: 0px !important;
        margin-right: 15px;
    }

    .div-filter-cont {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    .imageSalaFilter{
        width: 20%;
    }

    .div-filter-cont{
        display: flex;
    }

    .containerSM{
        display: flex;
        flex-direction: column;
    }

    .conr-lbl-filter {
        display: flex;
        margin-right: 15px;
        justify-content: space-between;
    }

    .conr-lbl-filter .iconTheme{
        margin-left: 0px;
    }

    .aside-container{
        width: 100%;
        display: flex;
        flex-direction: row;
    }

    .aside-container button{
        height: 50px;
    }

    .ContainerIconName{
        height: 45px;
        margin-top: 0px;
        margin-right: 15px;
        justify-content: center;
    }

    .ContainerIconName:hover ~ .containerLabelFilter,
    .ContainerIconName ~ .containerLabelFilter:hover{
        display: block;
        color: #50cd89;
        cursor: pointer;
    }

    .containerLabelFilter{
        width: 100%;
        padding: 10px;
        left: 0;
        display: none;
        position: absolute;
        border-radius: 5px;
        background-color: #323248;
    }
}

@media(max-width: 1295px){
    .calendarNav .form-floating{
        width: 20%;
    }

    #container-btn-agendar{
        width: 98%;
        display: flex;
        margin-left: 1%;
        align-items: center;
    }
}

@media(min-width:992px){
    .image-signup{
        max-width: 300px;
        width: 100%;
    }
}

@media(max-width:992px){
    .iconFilter{
        padding: 10px 2px;
    }

    .container-con{

    }
}

@media(max-width: 773px){
    .filter-color{
        padding: 12px 10px;
    }

    .filter-color:hover{
        background-color: #282c34;
    }

    .imageSalaFilter {
        width: 30%;
    }

    .ContainerIconName{
        width: 30%;
    }
}

@media(max-width: 750px){
    .containerFilter{
        flex-direction: column;
    }

    .container-menu {
        flex-direction: column;
    }

    .container-menu img {
        margin-bottom: 20px;
    }

    .modal-dialog.modal-lg.modal-dialog-centered {
        width: 90%;
        max-width: none;
    }

    .container-right{
        margin-bottom: 10px;
    }

    .div-filter-cont{
        justify-content: space-evenly;
    }

    .ContainerIconName{
        width: 100%;
    }
}

@media(max-width: 576px){
    .modal-dialog.modal-lg.modal-dialog-centered {
        width: 100%;
        max-width: none;
        margin-left: 0;
    }
}

@media(max-width: 575px){
    .containerInput{
        flex-direction: column;
        justify-content: space-between;
    }

    .containerInput .form-floating {
        width: 90%;
    }

    .containerButtomRecove{
        margin-top: 15px;
    }

    #container-btn-agendar,
    .calendarNav {
        display: flex;
        flex-direction: column;
    }

    #agendarBtn{
        width: 92% !important;
        margin-top: 20px;
    }

    .form-floating{
        width: 100% !important;
        margin-top: 20px;
        margin-left: 0px !important;
    }

    .calendarNav h3{
        width: 100%;
    }

    .sw-label{
        margin-top: 20px;
    }

    #agendaForm .containerInput {
        height: auto !important;
    }

    .invitadosContainer .form-floating{
        width: 100% !important;
    }

    .form-floating-check {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .form-floating-check label{
        margin-left: 10px;
    }

    .container-color{
        display: flex;
        flex-direction: row;
        margin: 27px 0px;
    }

    .containerPrivateIcons svg{
        margin-top: 16px;
    }

    #containerInvitados{
        width: 100%;
        margin-left: 0%;
    }

    .imageSalaFilter {
        width: 43%;
    }
}

@media(max-width:550px){
    .header{
        flex-direction: column;
    }

    .header-cont-1{
        width: 100%;
        margin-left: 0px;
        margin-bottom: 10px;
        justify-content: space-around;
    }

    .header button{
        width: 90%;
        margin-right: 0px;
    }
}

@media(max-width:467px){
    .aside-container{
        flex-direction: column;
    }

    .header-cont-1{
        flex-direction: column;
    }

    h1.mr-10.text-xl.text-gray-500.fond-bold {
        display: contents;
        margin-left: 10px;
    }

    .iconMovMont{
        margin-left: 0px;
    }
}

@media(max-width:370px){
    .container-pass-acc{
        display: flex;
        flex-direction: column;
    }

    .container-pass-acc a{
        margin: 10px 0px;
    }

    #loginForm .mt-1{
        padding-top: 25px;
    }

    .imageSalaFilter {
        width: 50%;
    }
}